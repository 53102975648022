import React from "react"

import Layout from "../components/layout"
import "../styles/document.css"

import sierraLeoneFinal from "../images/docs/de4a-sierra-leone-final.pdf"
import countryDiagnosticSierraLeoneKeyFindings
    from "../images/docs/DE4A Country Diagnostic_Sierra Leone.Key Findings.pdf"
import finalCartoonVersion from "../images/docs/FINAL FINAL  CARTOON VERSION OF THE  2021 FINANCE ACT.pdf"
import seirraLeoneEconomic from "../images/docs/Sierra Leone Economic Update_HR.pdf"
import stateOfEntrepreneurshipInSL from "../images/docs/The-State-of-Entrepreneurship-in-Sierra-Leone.pdf"

import {FaFile} from "react-icons/fa"

const About = () => {
    return (
        <Layout>
            <div>
                <div className="documentHero py-24">
                    <h1 className="text-center text-3xl md:text-4xl lg:text-6xl text-white font-extralight text-shadow">
                        Business Digest
                    </h1>
                </div>
                <div className="flex justify-center text-center mt-16">
                    <div className="mx-8 md:w-2/3 lg:1/3 mb-10">
                        <h3 className="text-3xl text-blue-500 font-extralight">
                            Get details from our documents listed below
                        </h3>
                        <p className="p-3">
                            Here are few documents to give you insights and additional
                            information of happenings in the Sierra Leone entrepreneurship and
                            economic space.
                        </p>
                    </div>
                </div>

                <div className="flex justify-center mb-20">
                    <div className="lg:w-2/3 mx-8">
                        <div class="grid grid-cols-1 md:grid-cols-3">
                            <div className="border border-blue-500 mx-4 my-3 p-5 rounded-md py-6">
                                <div className="flex items-baseline">
                                    <FaFile className="text-blue-500 mr-2"/>
                                    <a
                                        href={sierraLeoneFinal}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xl font-semibold"
                                    >
                                        Digital Economy Diagnostic Sierra Leone
                                    </a>
                                </div>
                                <p className="mt-2 mb-2">
                                    Rapid digital transformation is reshaping our global economy,
                                    permeating virtually every sector and aspect of daily life...
                                </p>
                                <a
                                    href={sierraLeoneFinal}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="py-2  text-blue-600 font-semibold tracking-wide"
                                >
                                    Read More
                                </a>
                            </div>

                            <div className="border border-blue-500 mx-4 my-3 p-5 rounded-md py-6">
                                <div className="flex items-baseline">
                                    <FaFile className="text-blue-500 mr-2"/>
                                    <a
                                        href={countryDiagnosticSierraLeoneKeyFindings}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xl font-semibold"
                                    >
                                        DE4A Country Diagnostic Sierra Leone Key Findings
                                    </a>
                                </div>
                                <p className="mt-2 mb-2">
                                    Over the last 5 years, entrepreneurship ecosystem, through
                                    incubators, accelerators and tech hubs, has grown 10-fold in
                                    Africa....
                                </p>
                                <a
                                    href={countryDiagnosticSierraLeoneKeyFindings}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="py-2  text-blue-600 font-semibold tracking-wide"
                                >
                                    Read More
                                </a>
                            </div>

                            <div className="border border-blue-500 mx-4 my-3 p-5 rounded-md py-6">
                                <div className="flex items-baseline">
                                    <FaFile className="text-blue-500 mr-2"/>
                                    <a
                                        href={finalCartoonVersion}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xl font-semibold"
                                    >
                                        Final Cartoon Version of the 2021 Finance Act
                                    </a>
                                </div>
                                <p className="mt-2 mb-2">
                                    This document presents highlight of a simplified version of
                                    the 2021 Finance Act. It intend to provide...
                                </p>
                                <a
                                    href={finalCartoonVersion}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="py-2  text-blue-600 font-semibold tracking-wide"
                                >
                                    Read More
                                </a>
                            </div>

                            <div className="border border-blue-500 mx-4 my-3 p-5 rounded-md py-6">
                                <div className="flex items-baseline">
                                    <FaFile className="text-blue-500 mr-2"/>
                                    <a
                                        href={seirraLeoneEconomic}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xl font-semibold"
                                    >
                                        Sierra Leone Economic Update HR
                                    </a>
                                </div>
                                <p className="mt-2 mb-2">
                                    After a historic contraction in 2020, the global economy is
                                    set to rebound this year, but recoveries are diverging by
                                    country...
                                </p>
                                <a
                                    href={seirraLeoneEconomic}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="py-2  text-blue-600 font-semibold tracking-wide"
                                >
                                    Read More
                                </a>
                            </div>

                            <div className="border border-blue-500 mx-4 my-3 p-5 rounded-md py-6">
                                <div className="flex items-baseline">
                                    <FaFile className="text-blue-500 mr-2"/>
                                    <a
                                        href={stateOfEntrepreneurshipInSL}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xl font-semibold"
                                    >
                                        The State of Entrepreneurship in Sierra Leone
                                    </a>
                                </div>
                                <p className="mt-2 mb-2">
                                    Interlinked, vibrant entrepreneurial ecosystems help to
                                    accelerate private sector-led growth and widespread economic
                                    returns...
                                </p>
                                <a
                                    href={stateOfEntrepreneurshipInSL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="py-2  text-blue-600 font-semibold tracking-wide"
                                >
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About
